/* ====== ELEMENTS ======= */
#news-sharer {
	clear: both;
	margin-top: 10px;
	padding: 10px 0;
	text-align: right;
}

#news-sharer a {
	font-size: 0;
}

#news-sharer a i {
	border-radius: 50%;
	display: inline-block;
	font-size: 18px;
	height: 30px;
	line-height: 30px;
	margin: 5px;
	text-align: center;
	transition: all 0.3s ease 0s;
	width: 30px;
	color: $color-text-contrast;
	background-color: $color-secondary !important;
}

#news-sharer a:hover i {
	height: 40px;
	line-height: 40px;
	margin: 0;
	width: 40px;
}

#news-sharer .facebook i:hover,
#news-sharer .like-on-facebook i:hover {
	background-color: #3b5998 !important;
}

#news-sharer .twitter i:hover {
	background-color: #00aced !important;
}

#news-sharer .google-1 i:hover,
#news-sharer .google-plus i:hover {
	background-color: #dd4b39 !important;
}

#news-sharer .linkedin i:hover {
	background-color: #007bb5 !important;
}

#news-sharer .pinterest i:hover {
	background-color: #cb2027 !important;
}
