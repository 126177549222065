html,
body {
	color: $color-text;
	background-color: $color-background;
}
body {
	overflow-x: hidden;
	margin-top: $navbar-height;
}

main {
	padding-top: 50px;
	padding-bottom: 50px;
}

h1,h2,h3,h4,h5,h6 {
	color: $color-primary;
}
