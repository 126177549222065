
html,
body {
	margin: 0;
	padding: 0;
	background-color: white;
	color: black;
}

a,
a:hover,
a:focus,
a:active,
a:visited {
	color: inherit;
	text-decoration: none;
	outline: none;

}
