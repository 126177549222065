
@import "elements/address";			// ADDRESS
@import "elements/btn";				// BTN
@import "elements/form";			// FORM
@import "elements/slider";			// SLIDER
@import "elements/modal";			// MODAL
@import "elements/thumb-img";		// THUMBNAIL IMAGES
@import "elements/thumb-menu";		// MENU THUMB
@import "elements/pagination";		// PAGINATION
@import "elements/map";				// GOOGLE MAP
@import "elements/box";				// BOX
@import "elements/table";			// TABLE
@import "elements/newsletter";		// NEWSLETTER
@import "elements/social-button";	// SOCIAL BUTTON
@import "elements/sharer-button";	// SHARER BUTTON
@import "elements/carousel";		// CAROUSEL

main {
  padding: 0;
}

nav {
  position: fixed;
  top: 0;
}

body {
  margin: 0;
}

.hero {
  margin-top: 8rem;
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  .plyr, .plyr__video-wrapper {
    height: 100%;
  }

  video {
    opacity: 0.75;
    object-fit: cover;
    min-height: 100%;
    object-position: 50% 50%;
  }
}

.hero--small {
  height: 50vh;
  min-height: 300px;
  @media (max-width: 480px){
    margin-top: 13rem;
  }
}

.hero__overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 25px;
  min-width: 300px;
  color: white;
  transform: translate3d(-50%, -50%, 0);

  h1, h2, h3, h4 {
    color: white;
  }
}

.container--padded {
  padding: 8rem 2rem;
}


table {
  min-width: 500px;
  margin: 0 auto;
  td {
    padding: 10px 0px;
    border-bottom: 1px solid rgba(230, 94, 94, 0.15);
  }
  tr td:first-child {
    padding-right: 10px;
  }
  tr td:last-child {
    min-width: 90px;
    text-align: right;
  }
}

.nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
  background: transparent;
}

.dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus {
  background: $primary_color;
  color: white;
  font-weight: 400;
}

.container--padded a {
  color: $primary_color;
}

.plyr__play-large, .plyr__controls {
  display: none!important;
}

a.btn {
  color: #efefef;
  background: $primary_color;
  padding: 10px 20px;
  border: none;
  margin: 10px 0;
  font-weight: 100;
  transition: all 250ms ease;
  &:focus, &:hover {
    transform: scale(1.05);
    color: white;
    border: none;
  }
}

body {
  font-weight: 100;
}

.navbar-nav .open .dropdown-menu{
  transform: translate(17%, 0%);
  margin-top: 17px;
}

.dropdown-menu > li > a {
  line-height: 2.5em;
}

footer {
  background: $primary_color;
  color: white;
}

b, strong {
  font-weight: 500;
}

.nav a {
  opacity: 0.8;
  transition: opacity 250ms ease;
  .fa {
    font-size: 19px;
  }
  &:focus, &:hover {
    opacity: 1;
  }
}

body {
  background-image: url(/website/images/background.png);
  background-size: cover;
}

@media (min-width: 1200px) {
  .container {
    width: 1000px;
  }
}

main {
  background: white;
}

a.btn {
  color: white;
}

table {
  max-width: 500px;
  width: 100%;
  min-width: auto!important;
}
@-webkit-keyframes pulse_animation {
  0% { transform: scale(1); }
  30% { transform: scale(1); }
  40% { transform: scale(1.08); }
  50% { transform: scale(1); }
  60% { transform: scale(1); }
  70% { transform: scale(1.05); }
  80% { transform: scale(1); }
  100% { transform: scale(1); }
}

@-moz-keyframes pulse_animation {
  0% { transform: scale(1); }
  30% { transform: scale(1); }
  40% { transform: scale(1.08); }
  50% { transform: scale(1); }
  60% { transform: scale(1); }
  70% { transform: scale(1.05); }
  80% { transform: scale(1); }
  100% { transform: scale(1); }
}

@-o-keyframes pulse_animation {
  0% { transform: scale(1); }
  30% { transform: scale(1); }
  40% { transform: scale(1.08); }
  50% { transform: scale(1); }
  60% { transform: scale(1); }
  70% { transform: scale(1.05); }
  80% { transform: scale(1); }
  100% { transform: scale(1); }
}

@keyframes pulse_animation {
  0% { transform: scale(1); }
  30% { transform: scale(1); }
  40% { transform: scale(1.18); }
  50% { transform: scale(1); }
  60% { transform: scale(1); }
  70% { transform: scale(1.05); }
  80% { transform: scale(1); }
  100% { transform: scale(1); }
}

.pulse {
  -webkit-animation: pulse_animation 5s infinite; /* Safari 4+ */
  -moz-animation:    pulse_animation 5s infinite; /* Fx 5+ */
  -o-animation:      pulse_animation 5s infinite; /* Opera 12+ */
  animation:    pulse_animation 5s infinite; /* Opera 12+ */
}