/************************  HP SLIDER ************************/

.carousel-indicators {
  position: absolute;
  bottom: 0px;
}

.carousel-indicators li {
  background-color: #DADADA;
  border: 0px solid #FFFFFF;
  border-radius: 2px;
  cursor: pointer;
  display: inline-block;
  height: 20px;
  margin: 1px;
  text-indent: -999px;
  width: 20px;
}

.carousel-indicators .active {
  background-color: #A81729;
  height: 20px;
  margin: 1px;
  width: 20px;
}

.slider-side-box {
  padding: 25px;
}

.carousel-inner > .item > img {
  width: 100%;
}

.carousel-caption {
  position: absolute;
  left: 15%;
  right: 15%;
  top: 37%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
  text-shadow: none;
  font-weight: normal;
}

@media (max-width: 767px) {
  .carousel-caption {
    top: 10%;
  }
}

.carousel-caption h2 {
  color: #FFFFFF;
  font-size: 3rem;
  /* Animation delays */
  animation-delay: 1s;
}

@media (max-width: 767px) {
  .carousel-caption h2 {
    font-size: 1rem;
  }
}

.carousel-caption p {
  color: #FFFFFF;
  font-size: 110%;
  animation-delay: 2s;
}

.carousel-control {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 15%;
  font-size: 20px;
  color: #fff;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  filter: alpha(opacity=50);
  opacity: .5;
}

.carousel-caption button {
  animation-delay: 3s;
}

/****EFFETTO FADE CAROUSEL BOOTSTRAP*****/

.carousel.carousel-fade .item {
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
}

.carousel.carousel-fade .active.item {
  opacity: 1;
}

.carousel.carousel-fade .active.left,
.carousel.carousel-fade .active.right {
  left: 0;
  z-index: 2;
  opacity: 0;
  filter: alpha(opacity=0);
}

.carousel.carousel-fade .next,
.carousel.carousel-fade .prev {
  left: 0;
  z-index: 1;
}

.carousel.carousel-fade .carousel-control {
  z-index: 3;
}