@mixin breakpoint-up($size) {
  @if( $size == 'sm' ) {
    @media (min-width: 768px) { @content; }
  } @elseif ( $size == 'md' ) {
    @media (min-width: 992px) { @content; }
  } @elseif ( $size == 'lg' ) {
    @media (min-width: 1200px) { @content; }
  }
}

@mixin breakpoint-down($size) {
  @if( $size == 'sm' ) {
    @media (max-width: 767px) { @content; }
  } @elseif ( $size == 'md' ) {
    @media (max-width: 991px) { @content; }
  } @elseif ( $size == 'lg' ) {
    @media (max-width: 1199px) { @content; }
  }
}

@mixin animate($speed: 0.3) {
	transition: all #{$speed}s ease-in-out;
}
