//FIX SAFARI
.row {
	&:after, &:before {
		content: '';
		display: block;
	}
} //.row

.flex {
	display: flex;
	flex-wrap: wrap;
}

.align-center {
	align-items: center;
}

@for $i from 1 through 20 {
  .width-#{$i * 5} {width: 1% * $i * 5;}
}