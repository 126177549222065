.navbar {
	font-family: $font-family-title;
	background-color: $primary_color;
	color: $color-text;
	padding-top: $navbar-height-padding;
	padding-bottom: $navbar-height-padding;
	border: 0;

	.container,
	.container-fluid {
		.navbar-header {
			margin-left: 0;
			margin-right: 0;

			.navbar-brand {
				margin-left: 0;
				height: auto;
				padding: 0;
				height: $navbar-height-content;
				> img {
					height: $navbar-height-content;
				}
			}
		}
	}
}


.navbar-nav {
	.open {
		.dropdown-menu {
			background-color: white;
		}
	}
	> li {
		@include breakpoint-up(sm) {
			margin: $navbar-item-margin;
		};
		> a {
			color: white;
			padding: $navbar-item-padding;
			line-height: $navbar-height-content;
			.flag {
				height: $navbar-height-content * 0.5;
				vertical-align: middle;
			}
		}

		.dropdown-menu {
			margin-top: $navbar-height-padding;
			.flag {
				height: $navbar-height-content * 0.5;
				vertical-align: middle;
			}
		}
	}
}

.nav > li {
	&.open > a,
	&.open > a:hover,
	&.open > a:focus {
	}
	&.active > a {
		font-weight: 400;
	}
	a:hover,
	a:focus {
		background: transparent;
	}
}

.navbar-fixed-top {
	.navbar-collapse {
		max-height: 82vh;
	}
}

.navbar-toggle {
	padding: 0;
	line-height: $navbar-height-content;
	width: $navbar-height-content;
	margin: 0;
	border-radius: 0;
	border: 0;
	@include animate();

	&:hover {
	}
}

.dropdown-menu {
	border: 0;
	box-shadow: none;
	padding: 0;
	border-radius: 0;
	font-size: 1em;
	> li {
		> a {
			padding: 0 15px;
			line-height: 3em;
		}
	}
}

.call-action {
	float: right;
	margin-right: 10px;
	height: $navbar-height-content;
	width: $navbar-height-content;
	text-align: center;
	@include animate();
	> i {line-height: $navbar-height-content;}

	&:hover {
	}
}

@media (max-width: 767px) {
	.navbar-nav .open .dropdown-menu {
		background-color: transparent;
		transform: none;
		-webkit-transition: none;
		-moz-transition: none;
		-ms-transition: none;
		-o-transition: none;
		transition: none;
		a {
			color: white;
		}
	}
	.btn--navi {
		display: none;
	}
}

@media (max-width: 991px) {
	.navbar-collapse.collapse {
		display: none !important;
	}
	.navbar-collapse.collapse.in {
		display: block !important;
	}
	.navbar-toggle, .call-action {
		display: block!important;
	}
	.navbar-header {
		float: none;
	}
	.btn--navi {
		display: inline-block!important;
	}
	.navbar-nav .open .dropdown-menu {
		transform: translate(0, 0)!important;
		-webkit-transform: translate(0, 0)!important;
	}
	h2 {
		font-size: 1em!important;
	}
}
.btn--mobile-hide {
	display: none!important;
}
.btn--mobile {
	float: left;
	display: inline-block;
}
@media (min-width: 992px) {
	.navbar-collapse.collapse {
		display: block !important;
	}
	.navbar-header {
		float: left;
	}
}
@media (min-width: 642px) {

	.btn--mobile-hide {
		display: inline-block!important;
	}
	.btn--mobile {
		display: none;
	}
}

.btn--tablet-hide {
	display: none;
}
.btn--tablet {
	float: left;
	display: inline-block;
}
@media (min-width: 992px) {

	.btn--tablet-hide {
		display: inline-block;
	}
	.btn--tablet {
		display: none;
	}
}
p img {
	max-width: 100%;
}

video {
	min-width: 100%!important;
}